import { useState, useEffect } from 'react';
import { Grid, SimpleGrid, Modal, Button, Text, Group } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import Cookies from 'js-cookie';
import Styles from '../assets/styles/pages/home.module.css';
import WelcomeBox from '../components/core/welcome-box';
import NavCard from '../components/core/nav-card';
import { useConsultation } from '../hooks/use-consultation';
import Container from '../components/layout/container';

export const Home = () => {
	const { consultation } = useConsultation();

	const isTelegram = Cookies.get('isTelegram') === 'true';

	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		if (!isTelegram) {
			setIsModalOpen(true);
		}
	}, [isTelegram]);

	const clearCookies = () => {
		const cookies = document.cookie.split(';');
		cookies.forEach((cookie) => {
			const name = cookie.split('=')[0].trim();
			document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
		});
	};

	const handleLoginClick = () => {
		clearCookies();
		setIsModalOpen(false);
		window.location.href = import.meta.env.VITE_LOGIN_URL;
	};

	const handleCreateClick = () => {
		clearCookies();
		setIsModalOpen(false);
		window.location.href = 'https://telegram.org/';
	};

	return (
		<Container>
			<WelcomeBox />

			<Modal
				opened={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				withCloseButton={false} // Hides the default close button
				centered // Center the modal in the middle of the page
				closeOnClickOutside={true} // Disable closing on outside click
				overlayOpacity={0.55}
				overlayBlur={3}
				styles={{
					content: {
						backgroundColor: '#f0f4f8',
						padding: '2rem',
						borderRadius: '15px',
						width: '900px', // Set custom width to make the modal more rectangular
						height: '350px', // Optionally set a specific height for the rectangle shape
					},
					inner: {
						// Ensure the modal is centered properly
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					},
				}}
			>
				<Group position="center" mb="md">
					<IconAlertCircle size={50} color="#1c7ed6" />
				</Group>

				<Text align="center" weight={700} size="xl" mb="sm">
					Sign in with Telegram!
				</Text>

				<Text align="center" size="md" color="dimmed" mb="lg">
					To access all features, please sign in with your Telegram account or
					create a new one to continue.
				</Text>

				<Group position="apart" mt="md" style={{ width: '100%' }}>
					{/* Buttons spaced apart */}
					<Button
						size="lg"
						radius="xl"
						variant="gradient"
						gradient={{ from: 'teal', to: 'lime', deg: 105 }}
						onClick={handleCreateClick} // Clear cookies and open the link in a new tab
					>
						Create
					</Button>
					<Button
						size="lg"
						radius="xl"
						variant="gradient"
						gradient={{ from: 'blue', to: 'cyan', deg: 105 }}
						onClick={handleLoginClick} // Clear cookies and then redirect to login URL
					>
						Log In
					</Button>
				</Group>
			</Modal>

			<Grid className={Styles['flex-container-one']}>
				<SimpleGrid
					cols={2}
					spacing="xl"
					breakpoints={[{ maxWidth: 625, cols: 1, spacing: 'md' }]}
				>
					<NavCard
						imageName="consultation_doctor"
						title="See a doctor"
						path={consultation?.status ? 'waitingroom' : 'booking'}
					/>
					<NavCard imageName="inbox" title="Inbox" path="messages" />
					<NavCard
						imageName="provider_doctor"
						title="Provider"
						path="provider"
					/>
					<NavCard imageName="service" title="Service" path="services" />
				</SimpleGrid>
			</Grid>
		</Container>
	);
};
